// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./Satoshi/Satoshi-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./Satoshi/Satoshi-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./Satoshi/Satoshi-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./Satoshi/Satoshi-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./Satoshi/Satoshi-Black.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Satoshi\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:300;font-style:normal;font-display:swap}@font-face{font-family:\"Satoshi\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:\"Satoshi\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:\"Satoshi\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"truetype\");font-weight:700;font-style:normal;font-display:swap}@font-face{font-family:\"Satoshi\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\");font-weight:800;font-style:normal;font-display:swap}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
