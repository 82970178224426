import Vue from "vue";

Vue.mixin({
  data() {
    return {
      defaultLanguage: "",
    };
  },

  mounted() {
    if (
      this.$application &&
      this.$application.i18n &&
      this.$application.i18n.defaultLocale
    ) {
      this.defaultLanguage = this.$application.i18n.defaultLocale;
    }
  },

  computed: {
    $homepageLink() {
      if (this.$i18n.locale === this.defaultLanguage) {
        return "/";
      } else {
        return `/${this.$i18n.locale}`;
      }
    },
  },

  methods: {
    $returnRating(rating) {
      if (!rating) rating = 1;

      rating = Number(rating);
      let customRating;

      if (rating < 1) {
        customRating = 1;
      } else if (rating > 5) {
        customRating = 5;
      } else customRating = rating;

      return customRating;
    },

    $returnNotSelectedRate(rating) {
      if (!rating) rating = 1;

      rating = Number(rating);
      let notSelectedRate;

      if (rating < 1) {
        notSelectedRate = 4;
      } else if (rating > 5) {
        notSelectedRate = 0;
      } else notSelectedRate = 5 - rating;

      return notSelectedRate;
    },

    $scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 200);
    },
  },
});
