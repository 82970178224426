
import * as vClickOutside from "v-click-outside";
export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      menuOpen: false,
      languageSwitcherOpen: false,
    };
  },

  watch: {
    $route() {
      this.closeMenu();
    },
  },

  methods: {
    openLanguageSwitcher() {
      this.languageSwitcherOpen = true;
    },

    closeLanguageSwitcher() {
      this.languageSwitcherOpen = false;
    },

    toggleMenu(e) {
      const el = e.currentTarget;

      if (el) {
        el.classList.toggle("menuOpen");

        this.menuOpen = el.classList.contains("menuOpen");
      }

      const element = document.documentElement;
      if (element) {
        if (this.menuOpen) {
          element.classList.add("overflow-hidden");
        } else {
          element.classList.remove("overflow-hidden");
        }
      }
    },
    closeMenu() {
      this.menuOpen = false;
      const openClass = document.getElementsByClassName("menuOpen");
      if (openClass && openClass[0]) {
        openClass[0].classList.remove("menuOpen");
      }

      const element = document.documentElement;
      if (element) {
        element.classList.remove("overflow-hidden");
      }
    },
  },
};
