
export default {
  data() {
    return {
      options: [
        {
          name: "icon",
          label: this.$t("label.icon"),
          type: "image",
          size: "24x24",
        },
        {
          name: "link",
          label: this.$t("label.link"),
          type: "link",
        },
      ],

      optionsInfo: [
        {
          name: "icon",
          label: this.$t("label.icon"),
          type: "image",
          size: "18x18",
        },
        {
          name: "description",
          label: this.$t("label.description"),
          type: "tipTap",
        },
      ],
    };
  },
};
