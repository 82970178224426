
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    noText: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    primary: {
      type: Boolean,
      default: true,
    },
    white: {
      type: Boolean,
      default: false,
    },
    link: {
      type: [String, Object],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonStyle() {
      let classes;

      if (this.primary) {
        if (this.white) {
          classes =
            "text-najmodavac-gray-600 md:hover:text-primary-blue-100 bg-white md:hover:bg-najmodavac-gray-100 border-transparent";
        } else {
          classes =
            "text-white bg-najmodavac-primary-blue-100 md:hover:bg-najmodavac-primary-blue-200 border-transparent";
        }
      } else {
        if (this.white) {
          classes =
            "text-white md:hover:text-najmodavac-gray-600 bg-transparent md:hover:bg-white border-white";
        } else {
          classes =
            "text-najmodavac-primary-blue-100 md:hover:text-white bg-white md:hover:bg-najmodavac-primary-blue-100 border-najmodavac-primary-blue-100";
        }
      }

      return classes;
    },
  },
};
