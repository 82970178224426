// Types
import mixins from"vue-typed-mixins";import{Page}from"@gauss/cms-shared";// Enums
import{Terms}from"../../enums/Terms";// Mixins
import Paginatable from"../../mixins/Paginatable";import{WithPartialInject}from"../../utils/withInject";import ProductListProvider from"../../mixins/ProductListProvider";import ProductFilterFieldSelector from"../../mixins/ProductFilterFieldSelector";import Debouncable from"../../mixins/Debouncable";import{toNumber}from"lodash";const SearchInject=WithPartialInject("isInsideSearchPage"),PERFORM_SEARCH_EVENT="perform-search";function genCategories(){var a,b;return null!==(a=this.$moduleOptions)&&void 0!==a&&null!==(b=a.searchCategories)&&void 0!==b&&b.length?this.$moduleOptions.searchCategories.map(a=>({slug:a,name:this.$t("core.term."+a)})):[{slug:Terms.Pages,name:this.$t("core.term.pages")},{slug:Terms.Products,name:this.$t("core.term.products")},{slug:Terms.Posts,name:this.$t("core.term.posts")},{slug:Terms.ProductCategories,name:this.$t("core.term.productCategories")}]}// Main
const ControllerSearch=mixins(Paginatable,SearchInject,ProductListProvider,ProductFilterFieldSelector,Debouncable({items:500,query:500,activeCategory:500})).extend({name:"ControllerSearch",props:{getProductCategories:{type:Boolean,default:!1},additionalPagesFilters:{type:Array,default:()=>[]},additionalProductsFilters:{type:Array,default:()=>[]},additionalPostsFilters:{type:Array,default:()=>[]},additionalCategoriesFilters:{type:Array,default:()=>[]}},data(){const a=genCategories.call(this),{query:b}=this.$route,c=b.search||"",d=b.page?toNumber(b.page):1,e=b.category||a[0].slug;return{categories:a,productCategories:[],options:{page:d,activeCategory:e,keywords:c}}},created(){if(!this.computedForm.activeCategory)throw this.$t("error.missingSearchCategories")},mounted(){this.isInsideSearchPage&&this.searchPageOpened&&(this.getAndSetAllProductCategories(),this.$nuxt.$on(PERFORM_SEARCH_EVENT,this.onPerformSearch))},beforeDestroy(){this.isInsideSearchPage&&this.searchPageOpened&&this.$nuxt.$off(PERFORM_SEARCH_EVENT,this.onPerformSearch)},pagination(){const{activeCategory:a}=this.computedForm;return{request:b=>this.genRequest(a,b),handleItems:b=>{a===Terms.Products?this.setProducts(b):this.setProducts([])}}},computed:{computedForm(){// eslint-disable-next-line @typescript-eslint/no-this-alias
const{options:a,status:b,refreshQueryParamsDebounced:c,fetchDataDebounced:d,debounce:e}=this;return{get page(){return a.page},set page(b){a.page===b||(// Setting page inside Paginatable results in data fetching, that line is not missing
a.page=b,c())},get search(){return a.keywords},set search(b){a.keywords===b||(// Watcher inside Paginatable resets page automatically, that line is not missing
// Setting page inside Paginatable results in data fetching, so that is not missing either
a.keywords=b,c())},get activeCategory(){return a.activeCategory},set activeCategory(f){e("activeCategory",()=>{b.pending||a.activeCategory===f||(a.activeCategory=f,a.page=1,d(),c())})}}},// ControllerSearch can be used both inside PageSearch and HeaderElement,
// that is why we need to make 2 ControllerSearch components work together on SearchPage
// which affects submit function
searchPageOpened(){var a;return(null===(a=this.$route.name)||void 0===a?void 0:a.split("_")[0])===this.$Page.Search}},methods:{async getAndSetAllProductCategories(){if(this.getProductCategories)try{var a;const{data:b}=await this.fetchProductCategories();// @ts-ignore
null!==b&&void 0!==b&&null!==(a=b.records)&&void 0!==a&&a.length&&(this.productCategories=b.records.map(a=>({id:a.id,name:a.name})))}catch(a){}},async filterProductByCategory(a){var b;const{search:c}=this.computedForm,d=this.options.page,e={keywords:c,page:d,limit:this.limit// find where from we can take the limit
},f=await this.fetchProducts(// @ts-ignore
e,"reset"===a?void 0:{key:"categories",value:[a]}),g=(null===f||void 0===f||null===(b=f.data)||void 0===b?void 0:b.records)||[];this.setProducts(g),this.items=g},setActiveCategory(a){a&&(this.computedForm.activeCategory=a.slug)},onPerformSearch({search:a,category:b,page:c}){this.options.activeCategory=b,this.options.keywords=a||"",this.options.page=c,this.fetchDataDebounced(),this.refreshQueryParamsDebounced()},genRequest(a,b){const c={[Terms.Pages]:this.fetchPages,[Terms.Products]:this.fetchProducts,[Terms.Posts]:this.fetchPosts,[Terms.ProductCategories]:this.fetchProductCategories},d=c[a];if(!d)throw"requestPerActiveCategory didn't find appropriate request";else return d(b)},fetchPages(a){const b=this.$api.cms.post.filterStandard({filters:[{key:"display",value:"components"},// @ts-ignore
...this.additionalPagesFilters],...a});return b},fetchProducts(a,b){// TODO: FIGURE OUT HOW TO REMOVE //@ts-ignore's !!
const c=[{key:"archived",value:!1,default:!0},...this.additionalProductsFilters];b&&c.push(b);const d=this.$api.product.web.filter({// @ts-ignore
filters:c,select:this.computedSelectParam,...a});return d},fetchPosts(a){const b=this.$api.cms.post.filterStandard({filters:[{key:"display",value:"legacy"},{key:"postTypeSlugs",value:["post"]},// @ts-ignore
...this.additionalPostsFilters],...a});return b},fetchProductCategories(a){const b=this.$api.product.web.category.filter({filters:[...this.additionalCategoriesFilters],sort:{param:"id",order:"asc"},tree:!0,...a});return b},refreshQueryParamsDebounced(){this.debounce("query",this.refreshQueryParams)},async refreshQueryParams(){if(!this.isInsideSearchPage)return;const{search:a,activeCategory:b,page:c}=this.computedForm,d={category:b,page:c};a&&(d.search=a);try{await this.$router.replace({// @ts-ignore
query:d})}catch(a){console.error(this.$t("error.redundantNavigationSkipped"))}},async submit(){if(this.isInsideSearchPage)return;const{search:a,activeCategory:b}=this.computedForm,c=this.options.page,d={category:b,page:c};if(a&&(d.search=a),this.searchPageOpened)this.$nuxt.$emit(PERFORM_SEARCH_EVENT,d);else try{await this.$router.push(this.localePath({name:Page.Search,// @ts-ignore
query:d}))}catch(a){console.error(this.$t("error.redundantNavigationSkipped"))}}},render(){const{computedForm:a,pageNumbers:b,categories:c,setActiveCategory:d,productCategories:e,filterProductByCategory:f,submit:g}=this,h={...this.paginatableSlotProps,form:a,pageNumbers:b,categories:c,setActiveCategory:d,productCategories:e,filterProductByCategory:f,submit:g};return this.renderContainer(h)}});export default ControllerSearch;